import {useCallback, useState} from 'react';
import {useViewport} from '../../Atoms';
import {ModalProps} from './Modal';
import {useWindowHeight} from '../../../hooks/useWindowHeight/useWindowHeight';

type UseModalProps = {
  onToggle?: (isOpen: boolean) => void;
  preventDismiss?: boolean;
} & Partial<Pick<ModalProps, 'title' | 'width' | 'height'>>;

export const useModal = ({
  title,
  onToggle,
  preventDismiss,
  width,
  height,
}: UseModalProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [triggerRef, setTriggerRef] = useState<HTMLElement | null>(null);

  const handleModalOpen = useCallback(() => {
    setIsOpen(true);
    onToggle?.(true);
  }, [onToggle]);

  const handleModalClose = useCallback(() => {
    setIsOpen(false);
    onToggle?.(false);
    triggerRef?.focus();
  }, [onToggle, triggerRef]);

  const isMobile = useViewport({device: 'mobile'});
  const windowHeight = useWindowHeight();
  const contentWidth = width ? width : undefined;
  const contentHeight = height ? height : 'var(--Modal-desktop-height)';

  const style = {
    width: isMobile ? undefined : contentWidth,
    height: isMobile ? windowHeight : contentHeight,
  };

  const modalProps: Omit<ModalProps, 'children'> = {
    title,
    isOpen,
    preventDismiss,
    onClose: handleModalClose,
    style,
  };

  return {
    isOpen,
    openModal: handleModalOpen,
    closeModal: handleModalClose,
    setTriggerRef,
    modalProps,
  };
};
