import {useEffect, useState} from 'react';
import {useAnimationFrame} from '../useAnimationFrame';

/**
 * Returns the windowHeight whenever the `resize` event is triggered.
 * This is used because 100vh is almost unusable on mobile devices
 * https://chanind.github.io/javascript/2019/09/28/avoid-100vh-on-mobile-web.html
 */
export const useWindowHeight = () => {
  const [windowHeight, setWindowHeight] = useState(
    typeof window !== 'undefined' ? window.innerHeight : 0
  );

  const handleResize = useAnimationFrame(() => {
    setWindowHeight(window.innerHeight);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return windowHeight;
};
