/**
 * Do not edit directly, use `yarn generate:icons`
 */

import React from 'react';
import {Base} from '../../../Internal/Base/';

const pathStrings = {
  16: [
    'M9 0a1 1 0 01.993.883L10 1v5h1V2a1 1 0 01.883-.993L12 1a1 1 0 01.993.883L13 2v4c.35 0 .687.06 1 .17V4a1 1 0 112 0v9a3 3 0 01-2.824 2.995L13 16h-1v-2h1a1 1 0 00.993-.883L14 13V9a1 1 0 00-.883-.993L13 8H8a1 1 0 00-.993.883L7 9v3.22l-2.958-.592L8.414 16H5.585l-4.124-4.125a1.5 1.5 0 011.211-2.553l.143.021L5 9.78V2a1 1 0 012 0v4.17c.26-.091.536-.148.824-.165L8 6V1a1 1 0 011-1z',
  ],

  24: [
    'M13.5 0a2.5 2.5 0 012.461 2.06 2.5 2.5 0 013 2 2.5 2.5 0 013.033 2.276L22 6.5V20a4 4 0 01-3.8 3.995L18 24v-2a2 2 0 001.994-1.85L20 20V6.5a.5.5 0 00-.992-.09L19 6.5V12h-2V4.5a.5.5 0 00-.992-.09L16 4.5V12h-2V2.5a.5.5 0 00-.992-.09L13 2.5V12h-2V4.5a.5.5 0 00-.992-.09L10 4.5V17H8.213L5.17 15.098a.778.778 0 00-.962.11.54.54 0 00-.084.655l.053.075L10.966 24H8.35l-5.705-6.774a2.54 2.54 0 01.147-3.433 2.778 2.778 0 013.27-.488l.166.097L8 14.508 8 4.5a2.5 2.5 0 012.336-2.495L10.5 2c.186 0 .367.02.54.059A2.497 2.497 0 0113.5 0z',
  ],
};

type Props = {size: keyof typeof pathStrings} & Omit<
  React.SVGAttributes<HTMLOrSVGElement>,
  'width' | 'height' | 'viewBox'
>;

export const IconMassage = ({
  size,
  className,
  ...rest
}: Props): React.ReactElement<React.ReactSVGElement> => {
  const paths = pathStrings[size].map((d: string, i: number) => (
    <path key={String(i)} d={d} fillRule="evenodd" />
  ));

  return (
    <Base
      as="svg"
      height={size}
      width={size}
      viewBox={`0 0 ${size} ${size}`}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      // Because viewPort(height + width) and viewBox might not have the same ratio
      // `preserveAspectRatio` indicates wether we should force a uniform scaling.
      // Therefore, `xMidYMid` centers the viewBox region within the viewPort region,
      // and `meet` scales the graphic until it meets the height and width of our viewPort.
      // More details here, https://www.digitalocean.com/community/tutorials/svg-preserve-aspect-ratio
      preserveAspectRatio="xMidYMid meet"
      fill="currentColor"
      {...rest}
    >
      {paths}
    </Base>
  );
};

IconMassage.displayName = 'IconMassage';
