import React, {useEffect} from 'react';
import {
  ColorBloomBaseYellow600 as ColourFilled,
  ColorBloomYellow200 as ColourUnfilled,
} from '@treatwell/design-tokens';
import {visuallyHiddenStyles} from '../VisuallyHidden';

const HALF_STAR_ID = 'half-star-gradient';

const fills = {
  empty: ColourUnfilled,
  half: `url(#${HALF_STAR_ID})`,
  full: ColourFilled,
};

const svgsPaths = {
  16: 'M4.767 14.77c-.787.589-1.599-.03-1.308-.997L4.65 9.802 1.44 7.324c-.777-.6-.474-1.61.496-1.61h4.006l1.252-3.993c.302-.965 1.309-.958 1.61 0l1.251 3.994h4.006c.976 0 1.272 1.01.496 1.609l-3.21 2.478 1.192 3.97c.29.968-.52 1.587-1.308.998l-3.233-2.417-3.232 2.417z',
  24: 'M6.92 22.639c-1.237.925-2.513-.047-2.056-1.567l1.873-6.24-5.044-3.895C.472 9.994.948 8.41 2.472 8.41h6.295l1.967-6.276c.476-1.517 2.058-1.505 2.53 0L15.23 8.41h6.295c1.533 0 2 1.586.779 2.528l-5.044 3.895 1.874 6.24c.456 1.52-.82 2.492-2.057 1.567L12 18.84l-5.08 3.799z',
  32: 'M9.245 28.706C7.6 29.88 5.903 28.647 6.51 26.72l2.492-7.91-6.708-4.936c-1.625-1.196-.992-3.205 1.035-3.205h8.373l2.616-7.955c.633-1.923 2.736-1.908 3.364 0l2.616 7.955h8.373c2.039 0 2.658 2.01 1.035 3.205l-6.708 4.937 2.492 7.91c.607 1.926-1.09 3.16-2.735 1.986L16 23.891l-6.755 4.815z',
};

export type StarSize = 16 | 24 | 32;

export type StarFill = 'empty' | 'half' | 'full';

export interface Props extends React.ComponentPropsWithoutRef<'svg'> {
  size: StarSize;
  fill: StarFill;
}

export const Star = ({size, fill, ...restProps}: Props) => {
  usePrependStarGradient(fill);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      {...restProps}
    >
      <path fill={fills[fill]} fillRule="evenodd" d={svgsPaths[size]} />
    </svg>
  );
};

export const starPath = (fill: string) => {
  return <path fill={fill} fillRule="evenodd" d={svgsPaths[32]} />;
};

const starGradientMarkup = `
<svg style="width:0; height:0">
  <defs>
    <linearGradient id="star-gradient">
      <stop offset="0%" stop-color="${ColourFilled}"></stop>
      <stop offset="50%" stop-color="${ColourFilled}"></stop>
      <stop offset="50%" stop-color="${ColourUnfilled}"></stop>
      <stop offset="100%" stop-color=${ColourUnfilled}></stop>
    </linearGradient>
    <linearGradient
      id=${HALF_STAR_ID}
      href="#star-gradient"
      spreadMethod="repeat">
    </linearGradient>
  </defs>
</svg>
`;

const gradientId = `${HALF_STAR_ID}-container`;

export const StarGradient = () => {
  return (
    <div
      id={gradientId}
      className={visuallyHiddenStyles.visuallyHidden}
      dangerouslySetInnerHTML={{__html: starGradientMarkup}}
    />
  );
};

const usePrependStarGradient = (fill: StarFill) => {
  useEffect(() => {
    if (fill !== 'half') {
      return;
    }
    const isGradientInDom = Boolean(document.querySelector(`#${gradientId}`));
    if (isGradientInDom) {
      return;
    }
    const container = document.createElement('div');
    container.id = gradientId;
    container.classList.add(visuallyHiddenStyles.visuallyHidden);
    container.innerHTML = starGradientMarkup;
    document.body.prepend(container);
  }, [fill]);
};
