/**
 * Do not edit directly, use `yarn generate:icons`
 */

import React from 'react';
import {Base} from '../../../Internal/Base/';

const pathStrings = {
  16: [
    'M1 1c2.307 0 3.977.99 4.833 2.904l.095.225.143.356-2.855 7.75a.569.569 0 00.467.761L3.75 13H7a2 2 0 01-1.85 1.995L5 15H3.75a2.568 2.568 0 01-2.464-3.297l.053-.16 2.58-7.004-.005-.013c-.512-.982-1.366-1.473-2.69-1.522L1 3V1zm11.72 4.375l1.56 1.25C13.562 7.525 12.61 8 11.5 8c-1.036 0-1.934-.414-2.634-1.201l-.147-.174 1.562-1.25c.347.434.729.625 1.219.625.436 0 .786-.15 1.102-.489l.117-.136zM11.5 1c1.659 0 3.066.76 4.158 2.205l.161.222-1.638 1.146C13.427 3.496 12.558 3 11.5 3c-.992 0-1.818.436-2.538 1.378l-.143.195-1.638-1.146C8.294 1.837 9.758 1 11.5 1z',
  ],

  24: [
    'M2 2.5c3.307 0 5.642 1.49 6.829 4.374l.107.275.116.309-3.19 11.163a1 1 0 001.04 1.271l.118-.016 2.075-.415 1.22.488a.823.823 0 001.04-.396.677.677 0 00-.216-.857l-.086-.052-.5-.25.894-1.788.5.25a2.677 2.677 0 011.197 3.591 2.823 2.823 0 01-3.401 1.42l-.171-.061-.668-.268-1.491.3c-.391.077-.792.077-1.181-.002L6 21.78a3 3 0 01-2.104-3.538l.044-.17L6.944 7.554l-.067-.157c-.86-1.888-2.35-2.827-4.612-2.894L2 4.5v-2zm16.912 5.455l1.676 1.09c-1.04 1.602-2.427 2.455-4.088 2.455-1.582 0-2.915-.774-3.937-2.231l-.151-.224 1.676-1.09C14.781 9.02 15.561 9.5 16.5 9.5c.88 0 1.621-.422 2.28-1.352l.132-.193zM16.5 2.5c2.433 0 4.493 1.073 6.114 3.154l.192.255-1.612 1.182C19.909 5.34 18.369 4.5 16.5 4.5c-1.785 0-3.268.765-4.517 2.358l-.177.233-1.612-1.182C11.842 3.66 13.969 2.5 16.5 2.5z',
  ],
};

type Props = {size: keyof typeof pathStrings} & Omit<
  React.SVGAttributes<HTMLOrSVGElement>,
  'width' | 'height' | 'viewBox'
>;

export const IconFace = ({
  size,
  className,
  ...rest
}: Props): React.ReactElement<React.ReactSVGElement> => {
  const paths = pathStrings[size].map((d: string, i: number) => (
    <path key={String(i)} d={d} fillRule="evenodd" />
  ));

  return (
    <Base
      as="svg"
      height={size}
      width={size}
      viewBox={`0 0 ${size} ${size}`}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      // Because viewPort(height + width) and viewBox might not have the same ratio
      // `preserveAspectRatio` indicates wether we should force a uniform scaling.
      // Therefore, `xMidYMid` centers the viewBox region within the viewPort region,
      // and `meet` scales the graphic until it meets the height and width of our viewPort.
      // More details here, https://www.digitalocean.com/community/tutorials/svg-preserve-aspect-ratio
      preserveAspectRatio="xMidYMid meet"
      fill="currentColor"
      {...rest}
    >
      {paths}
    </Base>
  );
};

IconFace.displayName = 'IconFace';
