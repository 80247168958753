/**
 * Do not edit directly, use `yarn generate:icons`
 */

import React from 'react';
import {Base} from '../../../Internal/Base/';

const pathStrings = {
  16: [
    'M12.264.373a4.505 4.505 0 013.723 4.747l-.507 7.399a3.39 3.39 0 01-3.383 3.159A3.301 3.301 0 018.8 12.514l-.366-8.78a3.273 3.273 0 013.83-3.36zM4.197.322h.233a3.273 3.273 0 013.137 3.222l-.003.185-.365 8.78A3.301 3.301 0 013.9 15.673a3.39 3.39 0 01-3.365-2.977l-.018-.182-.506-7.4A4.505 4.505 0 013.733.369c.154-.026.309-.042.464-.046zm6.353 6.19l.247 5.919a1.301 1.301 0 001.3 1.247 1.39 1.39 0 001.372-1.161l.016-.135.341-4.992-3.276-.878zm-5.104.001l-3.276.878.342 4.986.016.135c.11.663.686 1.16 1.372 1.16.654 0 1.2-.483 1.289-1.117l.011-.13.246-5.912zM4.347 2.321a1.273 1.273 0 00-.27.018A2.505 2.505 0 002 4.812l.006.166.025.38 3.503-.939.032-.773-.001-.13A1.273 1.273 0 004.347 2.32zm7.303.005c-.659.028-1.18.55-1.218 1.195v.13l.031.768 3.502.938.026-.374a2.505 2.505 0 00-2.07-2.64 1.273 1.273 0 00-.27-.017z',
  ],

  24: [
    'M18 1a6.226 6.226 0 015.14 7.022L21.5 19.5a4.077 4.077 0 01-4.036 3.5H17a3.62 3.62 0 01-3.602-3.98l.476-4.758a9.998 9.998 0 00-.067-2.516l-.882-5.734A4.403 4.403 0 0118 1zM6 1a4.402 4.402 0 015.102 4.8l-.027.212-.882 5.734c-.107.694-.14 1.397-.1 2.097l.033.42.476 4.757a3.62 3.62 0 01-3.41 3.975L7 23h-.465a4.077 4.077 0 01-4.002-3.3l-.033-.2L.86 8.022A6.226 6.226 0 016 1zm12 8l-2.247 2.246.03.196c.133.857.171 1.724.116 2.588l-.035.431-.476 4.758a1.62 1.62 0 001.464 1.775L17 21h.464c.98 0 1.817-.682 2.027-1.623l.03-.16 1.085-7.61L18 8.998zM6 9l-2.606 2.606 1.086 7.611.029.16c.198.889.956 1.547 1.865 1.617l.161.006H7l.147-.006A1.62 1.62 0 008.62 19.38l-.002-.08-.006-.081-.476-4.758-.035-.431a12 12 0 01.057-2.159l.058-.429.03-.196L6.002 9zm10.91-6.032a2.403 2.403 0 00-2.028 2.577l.02.163.474 3.087L17 7.171 17 5.001h2v2.17l1.96 1.961.2-1.393a4.226 4.226 0 00-3.489-4.766 2.403 2.403 0 00-.76-.005zm-9.821 0a2.403 2.403 0 00-.76.005 4.226 4.226 0 00-3.513 4.56l.024.206.2 1.393L5 7.17V5h2v2.171l1.624 1.624.474-3.087.02-.163a2.403 2.403 0 00-2.029-2.577z',
  ],
};

type Props = {size: keyof typeof pathStrings} & Omit<
  React.SVGAttributes<HTMLOrSVGElement>,
  'width' | 'height' | 'viewBox'
>;

export const IconSpa = ({
  size,
  className,
  ...rest
}: Props): React.ReactElement<React.ReactSVGElement> => {
  const paths = pathStrings[size].map((d: string, i: number) => (
    <path key={String(i)} d={d} fillRule="evenodd" />
  ));

  return (
    <Base
      as="svg"
      height={size}
      width={size}
      viewBox={`0 0 ${size} ${size}`}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      // Because viewPort(height + width) and viewBox might not have the same ratio
      // `preserveAspectRatio` indicates wether we should force a uniform scaling.
      // Therefore, `xMidYMid` centers the viewBox region within the viewPort region,
      // and `meet` scales the graphic until it meets the height and width of our viewPort.
      // More details here, https://www.digitalocean.com/community/tutorials/svg-preserve-aspect-ratio
      preserveAspectRatio="xMidYMid meet"
      fill="currentColor"
      {...rest}
    >
      {paths}
    </Base>
  );
};

IconSpa.displayName = 'IconSpa';
