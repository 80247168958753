import React from 'react';

import {TextStyle, Spacing} from '@treatwell/design-tokens';

import {Star, StarSize} from '../../Atoms/Star';
import styles from './Rating.module.css';
import {Inline} from '../../Layout';
import {Text} from '../../Atoms';

const starFill = (index: number, rating: number) => {
  if (index > rating && index - 1 < rating) {
    return 'half';
  } else if (rating >= index) {
    return 'full';
  } else {
    return 'empty';
  }
};

interface Size {
  textType: TextStyle;
  labelSpacing: Spacing;
  starSpacing: Spacing;
  alignmentAdjustment: number;
}

const sizes: {[key in StarSize]: Size} = {
  16: {
    textType: 'bodyHeavy',
    labelSpacing: 'xs',
    starSpacing: 'xxs',
    alignmentAdjustment: 2,
  },
  24: {
    textType: 'smHero',
    labelSpacing: 'xs',
    starSpacing: 'xs',
    alignmentAdjustment: 3,
  },
  32: {
    textType: 'mdHero',
    labelSpacing: 'md',
    starSpacing: 'sm',
    alignmentAdjustment: 2,
  },
};

export type Props = {
  rating: string | number;
  showRatingValue: boolean;
  size: StarSize;
  ariaLabel?: string;
  'data-cy'?: string;
};

export const Rating = ({
  rating,
  showRatingValue,
  ariaLabel,
  size: starSize,
  'data-cy': dataCy,
}: Props) => {
  const size = sizes[starSize];
  let numericRating: number = parseFloat(rating.toString().replace(',', '.'));
  let labelIncorrectRating = '';

  if (isNaN(numericRating)) {
    numericRating = 0;
    labelIncorrectRating = '-.-';
  }

  const stars = () => {
    const roundRating = Math.round(numericRating * 2) / 2;
    const range = [1, 2, 3, 4, 5];

    return range.map((i: number) => {
      const fill = starFill(i, roundRating);
      return <Star key={i} size={starSize} fill={fill} />;
    });
  };

  const ratingLabel = () => {
    if (!showRatingValue) {
      return null;
    }
    let value;
    if (labelIncorrectRating) {
      value = labelIncorrectRating;
    } else {
      value = numericRating > 0 ? rating : rating.toString().replace(/0/g, '-');
    }
    return (
      <Text
        type={size.textType}
        className={styles.label}
        aria-hidden={ariaLabel ? 'true' : 'false'}
        data-cy="rating-label"
      >
        {value}
      </Text>
    );
  };

  const starsStyle = {marginTop: `-${size.alignmentAdjustment}px`};

  return (
    <Inline
      align="center"
      space={size.labelSpacing}
      aria-label={ariaLabel}
      data-cy={dataCy}
    >
      {ratingLabel()}
      <Inline align="center" space={size.starSpacing} style={starsStyle}>
        {stars()}
      </Inline>
    </Inline>
  );
};
