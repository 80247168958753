/**
 * Do not edit directly, use `yarn generate:icons`
 */

import React from 'react';
import {Base} from '../../../Internal/Base/';

const pathStrings = {
  16: [
    'M12.743 8.3l2.082 2.915a2.951 2.951 0 01-.314 3.803 2.5 2.5 0 01-3.536 0 2.951 2.951 0 01-.315-3.803L12.743 8.3zM14 2a2.829 2.829 0 010 4l-8 8a2.828 2.828 0 11-4-4l8-8a2.828 2.828 0 014 0zm-1.258 9.74l-.454.638a.951.951 0 00.101 1.225.5.5 0 00.707 0 .951.951 0 00.168-1.119l-.066-.106-.456-.638zM8 6.828l-4.586 4.586a.828.828 0 001.087 1.246l.085-.074L8 9.172V6.828zm3.5-3.488l-.086.074L10 4.828v2.344l2.586-2.586a.828.828 0 00-1.087-1.246z',
  ],

  24: [
    'M21.5 2.5a3.536 3.536 0 010 5l-14 14a3.536 3.536 0 01-5-5l14-14a3.536 3.536 0 015 0zM19 11.343l2.749 4.14a3.901 3.901 0 01-.492 4.917 3.192 3.192 0 01-4.514 0 3.901 3.901 0 01-.492-4.916L19 11.344zm-6-2.516l-9.086 9.087a1.536 1.536 0 002.069 2.266l.103-.094 6.913-6.914V8.826zm6 6.132l-1.082 1.63c-.5.755-.4 1.757.24 2.397.465.465 1.22.465 1.685 0a1.901 1.901 0 00.322-2.26l-.083-.136L19 14.96zm-.983-11.14l-.103.095L15 6.827v4.345l5.087-5.086a1.535 1.535 0 00-2.069-2.266z',
  ],
};

type Props = {size: keyof typeof pathStrings} & Omit<
  React.SVGAttributes<HTMLOrSVGElement>,
  'width' | 'height' | 'viewBox'
>;

export const IconHairRemoval = ({
  size,
  className,
  ...rest
}: Props): React.ReactElement<React.ReactSVGElement> => {
  const paths = pathStrings[size].map((d: string, i: number) => (
    <path key={String(i)} d={d} fillRule="evenodd" />
  ));

  return (
    <Base
      as="svg"
      height={size}
      width={size}
      viewBox={`0 0 ${size} ${size}`}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      // Because viewPort(height + width) and viewBox might not have the same ratio
      // `preserveAspectRatio` indicates wether we should force a uniform scaling.
      // Therefore, `xMidYMid` centers the viewBox region within the viewPort region,
      // and `meet` scales the graphic until it meets the height and width of our viewPort.
      // More details here, https://www.digitalocean.com/community/tutorials/svg-preserve-aspect-ratio
      preserveAspectRatio="xMidYMid meet"
      fill="currentColor"
      {...rest}
    >
      {paths}
    </Base>
  );
};

IconHairRemoval.displayName = 'IconHairRemoval';
