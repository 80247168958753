/**
 * Do not edit directly, use `yarn generate:icons`
 */

import React from 'react';
import {Base} from '../../../Internal/Base/';

const pathStrings = {
  16: [
    'M2 1v2.322l1.073.43.031-.04a3.63 3.63 0 014.08-1.144l.185.074L8 2.91l.713-.303a3.63 3.63 0 014.118 1.025l.095.119L14 3.322V1h2v6.172a7.829 7.829 0 01-2.078 5.311l-.215.224-.828.829a5 5 0 01-3.271 1.457L9.343 15H6.657a5 5 0 01-3.344-1.282l-.192-.182-.828-.829A7.828 7.828 0 01.006 7.482L0 7.172V1h2zm7.456 3.465L8 5.088 6.58 4.48a1.628 1.628 0 00-2.072.721l-.08.17-.37.929L2 5.477v1.695c0 1.454.544 2.853 1.52 3.924l.187.197.829.828a3 3 0 001.923.872l.198.007h2.686a3 3 0 001.977-.743l.144-.136.829-.828a5.83 5.83 0 001.7-3.85L14 7.173V5.476l-2.057.824-.371-.929a1.628 1.628 0 00-2.116-.906zM8 7a2 2 0 011.995 1.85L10 9H6a2 2 0 012-2z',
  ],

  24: [
    'M3 3c0 1.56.051 3.12.154 4.678l2.933 1.099.057-.08a6.764 6.764 0 013.881-2.616l.279-.062.406-.08 1.29.858 1.29-.859.406.081a6.764 6.764 0 014.16 2.677l.056.081 2.934-1.1a71.08 71.08 0 00.145-3.551L21 3h2c0 2.316-.11 4.63-.33 6.934l-.118 1.152-.168 1.51a11.08 11.08 0 01-2.927 6.353l-.25.258-.45.45a8 8 0 01-5.354 2.337L13.1 22h-2.2a8 8 0 01-5.44-2.133l-.217-.21-.45-.45a11.08 11.08 0 01-3.132-6.255l-.045-.355-.168-1.511a73.223 73.223 0 01-.439-6.928L1 3h2zm10.688 5.076L12 9.202l-1.689-1.126-.1.028a4.764 4.764 0 00-2.53 1.885l-.135.212-.617 1.028L3.334 9.88l.102.984.167 1.51a9.08 9.08 0 002.366 5.17l.238.248.45.45a6 6 0 003.962 1.75l.28.007h2.202a6 6 0 004.04-1.564l.202-.193.45-.45a9.079 9.079 0 002.56-5.077l.044-.34.167-1.51.101-.985-3.594 1.348-.617-1.028a4.763 4.763 0 00-2.665-2.097l-.101-.028zM14 12a2 2 0 011.995 1.85L16 14H8a2 2 0 012-2h4z',
  ],
};

type Props = {size: keyof typeof pathStrings} & Omit<
  React.SVGAttributes<HTMLOrSVGElement>,
  'width' | 'height' | 'viewBox'
>;

export const IconBeard = ({
  size,
  className,
  ...rest
}: Props): React.ReactElement<React.ReactSVGElement> => {
  const paths = pathStrings[size].map((d: string, i: number) => (
    <path key={String(i)} d={d} fillRule="evenodd" />
  ));

  return (
    <Base
      as="svg"
      height={size}
      width={size}
      viewBox={`0 0 ${size} ${size}`}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      // Because viewPort(height + width) and viewBox might not have the same ratio
      // `preserveAspectRatio` indicates wether we should force a uniform scaling.
      // Therefore, `xMidYMid` centers the viewBox region within the viewPort region,
      // and `meet` scales the graphic until it meets the height and width of our viewPort.
      // More details here, https://www.digitalocean.com/community/tutorials/svg-preserve-aspect-ratio
      preserveAspectRatio="xMidYMid meet"
      fill="currentColor"
      {...rest}
    >
      {paths}
    </Base>
  );
};

IconBeard.displayName = 'IconBeard';
