/**
 * Do not edit directly, use `yarn generate:icons`
 */

import React from 'react';
import {Base} from '../../../Internal/Base/';

const pathStrings = {
  16: [
    'M7.5 11a2.5 2.5 0 010 5 2.5 2.5 0 010-5zm0 2a.5.5 0 100 1 .5.5 0 000-1zM10.106.553l1.788.894-2.575 5.15 5.251-2.5.86 1.806-7.5 3.571a26.457 26.457 0 01-1.762.746c-.355.135-.7.257-1.032.363C4.296 10.849 3.584 11 3 11v-.05A2.501 2.501 0 010 8.5a2.5 2.5 0 015 0l-.002.02a24.695 24.695 0 001.401-.551L10.106.553zM2.5 8a.5.5 0 100 1 .5.5 0 000-1z',
  ],

  24: [
    'M11 16a3 3 0 110 6 3 3 0 010-6zm0 2a1 1 0 100 2 1 1 0 000-2zm4.106-15.447l1.788.894-3.658 7.316 7.317-3.657.894 1.788-10 5a34.665 34.665 0 01-2.326 1.034c-.468.188-.924.357-1.36.502-.985.328-1.831.527-2.533.564L5 16a3 3 0 112.992-2.777l.387-.151a32.69 32.69 0 001.88-.824l4.847-9.695zM5 12a1 1 0 100 2 1 1 0 000-2z',
  ],
};

type Props = {size: keyof typeof pathStrings} & Omit<
  React.SVGAttributes<HTMLOrSVGElement>,
  'width' | 'height' | 'viewBox'
>;

export const IconHair = ({
  size,
  className,
  ...rest
}: Props): React.ReactElement<React.ReactSVGElement> => {
  const paths = pathStrings[size].map((d: string, i: number) => (
    <path key={String(i)} d={d} fillRule="evenodd" />
  ));

  return (
    <Base
      as="svg"
      height={size}
      width={size}
      viewBox={`0 0 ${size} ${size}`}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      // Because viewPort(height + width) and viewBox might not have the same ratio
      // `preserveAspectRatio` indicates wether we should force a uniform scaling.
      // Therefore, `xMidYMid` centers the viewBox region within the viewPort region,
      // and `meet` scales the graphic until it meets the height and width of our viewPort.
      // More details here, https://www.digitalocean.com/community/tutorials/svg-preserve-aspect-ratio
      preserveAspectRatio="xMidYMid meet"
      fill="currentColor"
      {...rest}
    >
      {paths}
    </Base>
  );
};

IconHair.displayName = 'IconHair';
