/**
 * Do not edit directly, use `yarn generate:icons`
 */

import React from 'react';
import {Base} from '../../../Internal/Base/';

const pathStrings = {
  16: [
    'M3 8v2H2v4h6v-4H7V8h1a2 2 0 012 2v6H0v-6a2 2 0 012-2h1zm10.5-8A2.5 2.5 0 0116 2.5v11a2.5 2.5 0 01-5 0v-11A2.5 2.5 0 0113.5 0zm0 2a.5.5 0 00-.492.41L13 2.5v11a.5.5 0 00.992.09L14 13.5v-11a.5.5 0 00-.5-.5zM6 0a2 2 0 012 2v5H6v6H4V7H2V2a2 2 0 012-2h2zm0 2H4v3h2V2z',
  ],

  24: [
    'M9 1a2 2 0 012 2v7H9v5.275l.328.103c1.08.334 1.852.51 2.673.582L12 14a1 1 0 00-.883-.993L11 13h-1v-2h1a3 3 0 013 3v9H2v-9a3 3 0 013-3h1v2H5a1 1 0 00-.993.883L4 14l.001.033c.97.069 1.841.25 2.999.598V10H5V3a2 2 0 012-2h2zm10 0a3 3 0 013 3v16a3 3 0 11-6 0V4a3 3 0 013-3zM4 16.04V21h8v-3.033c-.97-.069-1.841-.25-2.998-.598L9 20H7v-3.276l-.328-.102c-1.08-.334-1.85-.51-2.672-.582zM19 3a1 1 0 00-.993.883L18 4v16a1 1 0 001.993.117L20 20V4a1 1 0 00-1-1zM9 3H7v5h2V3z',
  ],
};

type Props = {size: keyof typeof pathStrings} & Omit<
  React.SVGAttributes<HTMLOrSVGElement>,
  'width' | 'height' | 'viewBox'
>;

export const IconNails = ({
  size,
  className,
  ...rest
}: Props): React.ReactElement<React.ReactSVGElement> => {
  const paths = pathStrings[size].map((d: string, i: number) => (
    <path key={String(i)} d={d} fillRule="evenodd" />
  ));

  return (
    <Base
      as="svg"
      height={size}
      width={size}
      viewBox={`0 0 ${size} ${size}`}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      // Because viewPort(height + width) and viewBox might not have the same ratio
      // `preserveAspectRatio` indicates wether we should force a uniform scaling.
      // Therefore, `xMidYMid` centers the viewBox region within the viewPort region,
      // and `meet` scales the graphic until it meets the height and width of our viewPort.
      // More details here, https://www.digitalocean.com/community/tutorials/svg-preserve-aspect-ratio
      preserveAspectRatio="xMidYMid meet"
      fill="currentColor"
      {...rest}
    >
      {paths}
    </Base>
  );
};

IconNails.displayName = 'IconNails';
