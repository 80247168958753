/**
 * Do not edit directly, use `yarn generate:icons`
 */

import React from 'react';
import {Base} from '../../../Internal/Base/';

const pathStrings = {
  16: [
    'M11.402.568l1.7 3.55a2.701 2.701 0 01-.887 3.38l.002.012c.092.625.282 1.23.563 1.794l.147.278c.363.644.542 1.509.57 2.611l.003.307v1h-1.586c-.576 0-1.131.206-1.566.577l-.14.13-.559.558c-.47.47-1.109.735-1.774.735a2.377 2.377 0 01-1.661-.677l-.128-.135-.464-.53a1.928 1.928 0 00-1.267-.65l-.184-.008H2.5v-1c0-1.146.148-2.053.478-2.737l.095-.18a6 6 0 00.656-1.762l.054-.31.009-.064a2.721 2.721 0 01-.963-3.024l.056-.154.064-.15L4.593.578l1.814.842-.569 1.224 1.56 1.142c.225.164.44.341.642.53.2-.189.414-.366.638-.53l1.517-1.11-.597-1.245 1.804-.864zM8.038 7.347l-.018.016a3.147 3.147 0 01-2.313.765l-.086.396a8 8 0 01-.806 2.041c-.107.19-.19.469-.245.836l-.016.118.067.007c.89.102 1.722.507 2.353 1.15l.154.165.463.53a.377.377 0 00.284.129.509.509 0 00.299-.097l.06-.052.559-.558a4.414 4.414 0 012.427-1.238l.226-.031-.016-.123a2.827 2.827 0 00-.193-.734l-.052-.102a8 8 0 01-.806-2.041l-.084-.391a3.147 3.147 0 01-2.257-.786zM6.218 5.4l-1.239-.906-.216.466a.72.72 0 00.34.951l.09.037.407.132c.398.13.83.03 1.13-.247-.161-.158-.332-.3-.512-.433zm4.856-.889l-1.215.89c-.18.131-.351.274-.511.429.268.251.641.357 1.002.283l.127-.033.406-.132a.7.7 0 00.45-.882l-.035-.086-.224-.469z',
  ],

  24: [
    'M16.936.649l2.084 5.556a3.44 3.44 0 01-2.053 4.444l-.18.059-.13.039A5.144 5.144 0 0112 9.863a5.143 5.143 0 01-3.21 1.1l-.302 1.208a8 8 0 01-1.395 2.905l-.226.283c-.417.5-.696 1.305-.81 2.421l-.02.223.127.004A6.12 6.12 0 0110 19.596l.207.197.914.914a1 1 0 00.576.284l.131.009h.344a1 1 0 00.608-.206l.099-.087.914-.914a6.121 6.121 0 014.043-1.786l.126-.004-.02-.223c-.103-1.023-.346-1.786-.708-2.29l-.101-.131a8 8 0 01-1.387-2.419c.4-.03.798-.096 1.192-.196l.295-.082.129-.039.214-.068.145-.053c.194.477.45.926.76 1.338l.187.236c.86 1.03 1.284 2.553 1.328 4.564L20 19v1h-1.879a4.121 4.121 0 00-2.744 1.047l-.17.16-.914.914a3 3 0 01-1.923.873l-.198.006h-.344a3 3 0 01-1.976-.743l-.145-.136-.914-.914a4.122 4.122 0 00-2.68-1.2L5.878 20H4v-1c0-2.068.377-3.65 1.185-4.737l.147-.187a6 6 0 001.135-2.1l.08-.29.28-1.12a3.44 3.44 0 01-1.92-4.142l.034-.11.04-.109L7.063.649l1.872.702-.944 2.518 1.128.846a35.822 35.822 0 012.881 2.407 35.198 35.198 0 012.302-1.965l.728-.555.976-.733-.943-2.518L16.936.65zM7.92 6.315l-.66-.495-.407 1.087a1.44 1.44 0 00.804 1.839l.13.046.13.039a3.144 3.144 0 002.594-.361 33.437 33.437 0 00-2.59-2.155zm8.819-.495l-.66.495c-.898.674-1.763 1.393-2.59 2.153.705.452 1.57.606 2.398.415l.196-.052.13-.039A1.44 1.44 0 0017.178 7l-.03-.092-.409-1.087z',
  ],
};

type Props = {size: keyof typeof pathStrings} & Omit<
  React.SVGAttributes<HTMLOrSVGElement>,
  'width' | 'height' | 'viewBox'
>;

export const IconBody = ({
  size,
  className,
  ...rest
}: Props): React.ReactElement<React.ReactSVGElement> => {
  const paths = pathStrings[size].map((d: string, i: number) => (
    <path key={String(i)} d={d} fillRule="evenodd" />
  ));

  return (
    <Base
      as="svg"
      height={size}
      width={size}
      viewBox={`0 0 ${size} ${size}`}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      // Because viewPort(height + width) and viewBox might not have the same ratio
      // `preserveAspectRatio` indicates wether we should force a uniform scaling.
      // Therefore, `xMidYMid` centers the viewBox region within the viewPort region,
      // and `meet` scales the graphic until it meets the height and width of our viewPort.
      // More details here, https://www.digitalocean.com/community/tutorials/svg-preserve-aspect-ratio
      preserveAspectRatio="xMidYMid meet"
      fill="currentColor"
      {...rest}
    >
      {paths}
    </Base>
  );
};

IconBody.displayName = 'IconBody';
