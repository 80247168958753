import React from 'react';

import {Badge, BadgeSize} from '../../Internal/Badge/Badge';
import {ColorPair, ColorPairTeal} from '../../Internal/Badge/colour-pair';
import {Icon} from '../Icon';

export type Props = {
  /**
   * Use `sm` with 16px icons and
   * use `lg` with 24px icons.
   *
   * The default is `sm`.
   */
  size?: BadgeSize;

  /**
   * Named pairs of background and foreground colours.
   * For example `ColorPairTeal` (the default) will use `ColorTeal100` and `ColorTeal10`.
   */
  colorPair: ColorPair;

  /**
   * The icon to render in the badge.
   */
  icon: Icon;
  className?: string;
};

/**
 * IconBadge renders an icon as a badge.
 * That is to say in a circle.
 */
export const IconBadge = ({
  colorPair = ColorPairTeal,
  size = 'sm',
  icon,
  className,
}: Props) => {
  return (
    <Badge colorPair={colorPair} size={size} className={className}>
      {icon}
    </Badge>
  );
};

IconBadge.displayName = 'IconBadge';
