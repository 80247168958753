/**
 * Do not edit directly, use `yarn generate:icons`
 */

import React from 'react';
import {Base} from '../../../Internal/Base/';

const pathStrings = {
  16: [
    'M13 4a3 3 0 013 3v8a1 1 0 01-1 1H1a1 1 0 01-1-1V7a3 3 0 013-3h10zm0 2H3a1 1 0 00-.993.883L2 7v7h12V7a1 1 0 00-.883-.993L13 6zM9 7v1.999L11 9v2H9v2H7v-2H5V9h2V7h2zm0-7a2 2 0 012 2l-.001 1h-2L9 2H7l-.001 1h-2L5 2a2 2 0 012-2h2z',
  ],

  24: [
    'M3 13v8h18v-8h2v9a1 1 0 01-1 1H2a1 1 0 01-1-1v-9h2zm10-3v3h3v2h-3v3h-2v-3H8v-2h3v-3h2zm0-9a3 3 0 012.995 2.824L16 4v1h2a5 5 0 015 5v1h-2v-1a3 3 0 00-2.824-2.995L18 7H6a3 3 0 00-2.995 2.824L3 10v1H1v-1a5 5 0 015-5h2V4a3 3 0 012.824-2.995L11 1h2zm0 2h-2a1 1 0 00-.993.883L10 4v1h4V4a1 1 0 00-.77-.974l-.113-.02L13 3z',
  ],
};

type Props = {size: keyof typeof pathStrings} & Omit<
  React.SVGAttributes<HTMLOrSVGElement>,
  'width' | 'height' | 'viewBox'
>;

export const IconMedicalDental = ({
  size,
  className,
  ...rest
}: Props): React.ReactElement<React.ReactSVGElement> => {
  const paths = pathStrings[size].map((d: string, i: number) => (
    <path key={String(i)} d={d} fillRule="evenodd" />
  ));

  return (
    <Base
      as="svg"
      height={size}
      width={size}
      viewBox={`0 0 ${size} ${size}`}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      // Because viewPort(height + width) and viewBox might not have the same ratio
      // `preserveAspectRatio` indicates wether we should force a uniform scaling.
      // Therefore, `xMidYMid` centers the viewBox region within the viewPort region,
      // and `meet` scales the graphic until it meets the height and width of our viewPort.
      // More details here, https://www.digitalocean.com/community/tutorials/svg-preserve-aspect-ratio
      preserveAspectRatio="xMidYMid meet"
      fill="currentColor"
      {...rest}
    >
      {paths}
    </Base>
  );
};

IconMedicalDental.displayName = 'IconMedicalDental';
