/**
 * Do not edit directly, use `yarn generate:icons`
 */

import React from 'react';
import {Base} from '../../../Internal/Base/';

const pathStrings = {
  16: [
    'M2.5 12a1.5 1.5 0 11-.001 3.001A1.5 1.5 0 012.5 12zM8 12a1.5 1.5 0 11-.001 3.001A1.5 1.5 0 018 12zm5.5 0a1.5 1.5 0 11-.001 3.001A1.5 1.5 0 0113.5 12zm-11-5.5a1.5 1.5 0 11-.001 3.001A1.5 1.5 0 012.5 6.5zm5.5 0a1.5 1.5 0 11-.001 3.001A1.5 1.5 0 018 6.5zm5.5 0a1.5 1.5 0 11-.001 3.001A1.5 1.5 0 0113.5 6.5zM2.5 1a1.5 1.5 0 11-.001 3.001A1.5 1.5 0 012.5 1zM8 1a1.5 1.5 0 11-.001 3.001A1.5 1.5 0 018 1zm5.5 0a1.5 1.5 0 11-.001 3.001A1.5 1.5 0 0113.5 1z',
  ],

  24: [
    'M5 3a2 2 0 11.001 3.999A2 2 0 015 3zm0 7a2 2 0 11.001 3.999A2 2 0 015 10zm0 7a2 2 0 11.001 3.999A2 2 0 015 17zm7-14a2 2 0 11.001 3.999A2 2 0 0112 3zm0 7a2 2 0 11.001 3.999A2 2 0 0112 10zm0 7a2 2 0 11.001 3.999A2 2 0 0112 17zm7-14a2 2 0 11.001 3.999A2 2 0 0119 3zm0 7a2 2 0 11.001 3.999A2 2 0 0119 10zm0 7a2 2 0 11.001 3.999A2 2 0 0119 17z',
  ],
};

type Props = {size: keyof typeof pathStrings} & Omit<
  React.SVGAttributes<HTMLOrSVGElement>,
  'width' | 'height' | 'viewBox'
>;

export const IconAllTreatments = ({
  size,
  className,
  ...rest
}: Props): React.ReactElement<React.ReactSVGElement> => {
  const paths = pathStrings[size].map((d: string, i: number) => (
    <path key={String(i)} d={d} fillRule="evenodd" />
  ));

  return (
    <Base
      as="svg"
      height={size}
      width={size}
      viewBox={`0 0 ${size} ${size}`}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      // Because viewPort(height + width) and viewBox might not have the same ratio
      // `preserveAspectRatio` indicates wether we should force a uniform scaling.
      // Therefore, `xMidYMid` centers the viewBox region within the viewPort region,
      // and `meet` scales the graphic until it meets the height and width of our viewPort.
      // More details here, https://www.digitalocean.com/community/tutorials/svg-preserve-aspect-ratio
      preserveAspectRatio="xMidYMid meet"
      fill="currentColor"
      {...rest}
    >
      {paths}
    </Base>
  );
};

IconAllTreatments.displayName = 'IconAllTreatments';
