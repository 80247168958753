import React from 'react';
import clsx from 'clsx';
import {Spacing} from '@treatwell/design-tokens';

import {Base} from '../Base';
import styles from './Badge.module.css';
import {ColorPair} from './colour-pair';

export type BadgeSize = Extract<Spacing, 'sm' | 'lg'>;

export interface Props {
  children?: React.ReactNode;
  /**
   * A pair of background and foreground colours.
   * For example `ColorPairTeal` will use `ColorTeal100` and `ColorTeal10`.
   */
  colorPair: ColorPair;
  size?: BadgeSize;
  className?: string;
}

/**
 * The badge component is a base that can be composed to create circular badge components.
 * Its children will be centred within its circle.
 */
export const Badge = ({
  children,
  colorPair,
  size = 'sm',
  className,
  ...props
}: Props) => {
  const badgeStyles = clsx(styles.badge, styles[size], className);

  return (
    <Base
      aria-hidden="true"
      className={badgeStyles}
      style={{...colorPair}}
      {...props}
    >
      {children}
    </Base>
  );
};

Badge.displayName = 'Badge';
