import {
  ColorBloomTeal100,
  ColorBloomTeal700,
  ColorBloomYellow100,
  ColorBloomYellow700,
} from '@treatwell/design-tokens';

export type ColorPair = {
  color: string;
  backgroundColor: string;
};

export const ColorPairTeal: ColorPair = {
  color: ColorBloomTeal700,
  backgroundColor: ColorBloomTeal100,
};

export const ColorPairYellow: ColorPair = {
  color: ColorBloomYellow700,
  backgroundColor: ColorBloomYellow100,
};
